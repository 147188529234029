<template>
    <v-container class="text-center">
        <h1 style="color:#0D3282;font-size:4em;font-weight:bolder">NOSSOS SERVIÇOS</h1>
        <v-row style="margin-top:6em" class="d-flex align-center">
            <v-col cols="5">
                <h1 style="color:#0D3282;">LOCAÇÃO DE EQUIPAMENTOS</h1>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col>
                Além de serviços de terraplenagem, a HC Serafim Terraplenagem também possui frota própria com uma ampla variedade de máquinas e equipamentos de terraplenagem para locação. <br>
                Nossos equipamentos para terraplenagem constantemente passam por manutenção preventiva. Por isso, conseguimos oferecer serviços de terraplenagem com qualidade, eficiência, rapidez e segurança. Alguns equipamentos para terraplenagem que possuímos: <br><br>
                <v-list>
                    <v-list-item>
                        <v-icon style="color:#0D3282" class="ml-5 mr-5">mdi-tractor</v-icon> Escavadeiras hidráulicas
                    </v-list-item>
                </v-list>
                <v-list>
                    <v-list-item>
                        <v-icon style="color:#0D3282" class="ml-5 mr-5">mdi-tractor</v-icon> Mini escavadeira hidráulica
                    </v-list-item>
                </v-list>
                <v-list>
                    <v-list-item>
                        <v-icon style="color:#0D3282" class="ml-5 mr-5">mdi-tractor</v-icon> Mini retroescavadeira
                    </v-list-item>
                </v-list>
                <v-list>
                    <v-list-item>
                        <v-icon style="color:#0D3282" class="ml-5 mr-5">mdi-tractor</v-icon> Motoniveladora
                    </v-list-item>
                </v-list>
                <v-list>
                    <v-list-item>
                        <v-icon style="color:#0D3282" class="ml-5 mr-5">mdi-tractor</v-icon> Pá carregadeira
                    </v-list-item>
                </v-list>
                <v-list>
                    <v-list-item>
                        <v-icon style="color:#0D3282" class="ml-5 mr-5">mdi-tractor</v-icon> PC 180 com rompedor
                    </v-list-item>
                </v-list>
                <v-list>
                    <v-list-item>
                        <v-icon style="color:#0D3282" class="ml-5 mr-5">mdi-tractor</v-icon> Retroescavadeiras
                    </v-list-item>
                </v-list>
                <v-list>
                    <v-list-item>
                        <v-icon style="color:#0D3282" class="ml-5 mr-5">mdi-tractor</v-icon> Rolocompactador
                    </v-list-item>
                </v-list>
                <v-list>
                    <v-list-item>
                        <v-icon style="color:#0D3282" class="ml-5 mr-5">mdi-tractor</v-icon> Rompedor
                    </v-list-item>
                </v-list> 
                <v-list>
                    <v-list-item>
                        <v-icon style="color:#0D3282" class="ml-5 mr-5">mdi-tractor</v-icon> Trator esteira
                    </v-list-item>
                </v-list>
                Consulte a HC Serafim e vamos ajudá-lo com o equipamento para terraplenagem adequado ao serviço de terraplenagem a ser executado.      <br><br>       
                <v-dialog
                    v-model="teste"
                    width="900"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            class="mt-5"
                            elevation="8"  
                            rounded 
                            color="#0D3282"
                            v-bind="attrs"
                            v-on="on">
                            <div style="color:white">
                                <v-icon>mdi-image-multiple</v-icon> Galeria
                            </div>
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title class="text-h4">
                        <b style="color:#0D3282">Equipamentos</b>
                        </v-card-title>
                        <v-card-subtitle class="mt-5">
                        <b>Nossas Máquinas </b>  
                        </v-card-subtitle>
                        <v-divider class="mb-5"></v-divider>
                        <v-card-text>
                            <v-row>
                                <v-col
                                    class="d-flex child-flex"
                                    cols="5"
                                    v-for="(image,i) in equip"
                                    :key="i"
                                >
                                    <img 
                                        :src="require('@/assets/Equip/'+ image)" 
                                        width="300">
                                    <template v-slot:placeholder>
                                        <v-row
                                            class="fill-height ma-0"
                                            align="center"
                                            justify="center"
                                        >
                                            <v-progress-circular
                                            indeterminate
                                            color="grey lighten-5"
                                            ></v-progress-circular>
                                        </v-row>
                                    </template>
                                </v-col>
                            </v-row>        
                        </v-card-text>                        

                        <v-divider></v-divider>

                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="#0D3282"
                            fab
                            icon
                            @click="teste = false"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                    </v-dialog>
                
            </v-col>
        </v-row>
        <v-row style="margin-top:10em;margin-bottom:8em" class="d-flex align-center">
            <v-col>
                Terraplenagem é uma técnica que realiza o nivelamento do terreno, tornando-o apto a receber qualquer tipo de construção. <br>
                Estão elencado como Terraplenagem os seguintes serviços: <br><br>
                <v-list>
                    <v-list-item>
                        <v-icon style="color:#0D3282" class="ml-5 mr-5">mdi-tractor-variant</v-icon> Escavação de Pequeno/Médio e Grande Porte.
                    </v-list-item>
                </v-list>
                <v-list>
                    <v-list-item>
                        <v-icon style="color:#0D3282" class="ml-5 mr-5">mdi-tractor-variant</v-icon> Demolição Comercial/Residêncial, etc.
                    </v-list-item>
                </v-list>
                <v-list>
                    <v-list-item>
                        <v-icon style="color:#0D3282" class="ml-5 mr-5">mdi-tractor-variant</v-icon> Nivelamento de terrenos para construções, fundações e edificações.
                    </v-list-item>
                </v-list>
                <v-dialog
                    v-model="dialog"
                    width="900"
                >
                <template v-slot:activator="{ on, attrs }">
                <v-btn
                    class="mt-5"
                    elevation="8"  
                    rounded 
                    color="#0D3282"
                    v-bind="attrs"
                    v-on="on">
                    <div style="color:white">
                        <v-icon>mdi-image-multiple</v-icon> Galeria
                    </div>
                </v-btn>
                </template>
                <v-card>
                    <v-card-title class="text-h4">
                    <b style="color:#0D3282">Demolição & Terraplenagem</b>
                    </v-card-title>
                    <v-card-subtitle class="mt-5">
                     <b>Demolição</b>  
                    </v-card-subtitle>
                    <v-divider class="mb-5"></v-divider>
                    <v-card-text>
                        <v-row>
                            <v-col
                                class="d-flex child-flex"
                                cols="5"
                                v-for="(image,i) in imagens"
                                :key="i"
                            >
                                <img 
                                    :src="require('@/assets/Demo/'+ image)" 
                                    width="300">
                                <template v-slot:placeholder>
                                    <v-row
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center"
                                    >
                                        <v-progress-circular
                                        indeterminate
                                        color="grey lighten-5"
                                        ></v-progress-circular>
                                    </v-row>
                                </template>
                            </v-col>
                        </v-row>        
                    </v-card-text>
                    <v-card-subtitle class="mt-5">                       
                        <b>Terraplenagem</b>
                    </v-card-subtitle>
                    <v-divider class="mb-5"></v-divider>
                    <v-card-text>
                        <v-row>
                            <v-col
                                class="d-flex child-flex"
                                cols="5"
                                v-for="(image,i) in outras"
                                :key="i"
                            >
                                <img 
                                    :src="require('@/assets/Terra/Outras/'+ image)" 
                                    width="300">
                                <template v-slot:placeholder>
                                    <v-row
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center"
                                    >
                                        <v-progress-circular
                                        indeterminate
                                        color="grey lighten-5"
                                        ></v-progress-circular>
                                    </v-row>
                                </template>
                            </v-col>
                        </v-row>        
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="#0D3282"
                        fab
                        icon
                        @click="dialog = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="5">
                <h1 style="color:#0D3282;">TERRAPLANAGEM</h1>
            </v-col>            
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'Servicos',
     data: () => ({
      dialog: false,
      teste: false,
      equip:[
          'loc.jpg',
          '7.jpeg',
          '8.jpeg',
          '9.jpeg',
          '10.jpeg',
          '11.jpeg',
          '12.jpeg',
          '13.jpeg',      
      ],
      imagens:[
          '1.jpeg',
          '2.jpeg',
          '3.jpeg',
          '4.jpeg',
          '5.jpeg',
          '6.jpeg',
      ],
      outras:[
          '12.jpeg',
          '7.jpeg',
          '8.jpeg',
          '9.jpeg',
          '10.jpeg',
          '11.jpeg',
      ]
    }),
}
</script>

<style scoped>

</style>